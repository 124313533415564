var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-1",attrs:{"tile":""}},[_c('v-card-text',[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'graduation')
                ? _vm.$t(("education." + (_vm.$helpers.get(_vm.employee, 'graduation').toLowerCase())))
                : null,"label":_vm.$t('hr.employee.education.graduation'),"index":0,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('graduation-level-picker',{staticStyle:{"max-width":"240px"},attrs:{"outlined":"","dense":"","color":"tertiary","clearable":""},model:{value:(_vm.employeePayload.graduation),callback:function ($$v) {_vm.$set(_vm.employeePayload, "graduation", $$v)},expression:"employeePayload.graduation"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'school'),"label":_vm.$t('hr.employee.education.school'),"rules":"max:50","index":1,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"50","error-messages":errors},model:{value:(_vm.employeePayload.school),callback:function ($$v) {_vm.$set(_vm.employeePayload, "school", $$v)},expression:"employeePayload.school"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'faculty'),"label":_vm.$t('hr.employee.education.faculty'),"rules":"max:50","index":2,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"50","error-messages":errors},model:{value:(_vm.employeePayload.faculty),callback:function ($$v) {_vm.$set(_vm.employeePayload, "faculty", $$v)},expression:"employeePayload.faculty"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'department'),"label":_vm.$t('hr.employee.education.department'),"rules":"max:50","index":3,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"50","error-messages":errors},model:{value:(_vm.employeePayload.department),callback:function ($$v) {_vm.$set(_vm.employeePayload, "department", $$v)},expression:"employeePayload.department"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'graduationGrade'),"label":_vm.$t('hr.employee.education.graduation_grade'),"rules":"min_value:0|max_value:5","index":4,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"type":"number","min":"0","max":"5","step":".1","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.graduationGrade),callback:function ($$v) {_vm.$set(_vm.employeePayload, "graduationGrade", _vm._n($$v))},expression:"employeePayload.graduationGrade"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'academicTitle')
                ? _vm.$t(("academic_title." + (_vm.$helpers.get(_vm.employee, 'academicTitle').toLowerCase())))
                : null,"label":_vm.$t('hr.employee.education.academic_title'),"index":5,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('academic-title-picker',{staticStyle:{"max-width":"240px"},attrs:{"outlined":"","dense":"","color":"tertiary","clearable":""},model:{value:(_vm.employeePayload.academicTitle),callback:function ($$v) {_vm.$set(_vm.employeePayload, "academicTitle", $$v)},expression:"employeePayload.academicTitle"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":(_vm.$helpers.get(_vm.employee, 'languages') || [])
                .map(function (language) { return _vm.$t(("education_languages." + (language.toLowerCase()))); })
                .join(', '),"label":_vm.$t('hr.employee.education.languages'),"index":6,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('education-language-picker',{staticStyle:{"max-width":"240px"},attrs:{"multiple":"","outlined":"","dense":"","color":"tertiary","clearable":""},model:{value:(_vm.employeePayload.languages),callback:function ($$v) {_vm.$set(_vm.employeePayload, "languages", $$v)},expression:"employeePayload.languages"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'licenseUrl'),"label":_vm.$t('hr.employee.education.licence_file'),"index":7,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable,"action-container-class":{ 'align-center': true },"hide-save":""},on:{"click:edit":_vm.onEditClick,"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"value",fn:function(ref){
                var value = ref.value;
return [(value)?[_c('licence-file',{staticStyle:{"max-width":"240px"},attrs:{"readonly":""},model:{value:(_vm.employeePayload.licenseUrl),callback:function ($$v) {_vm.$set(_vm.employeePayload, "licenseUrl", $$v)},expression:"employeePayload.licenseUrl"}})]:_c('span',[_vm._v(" - ")])]}},{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('licence-file',{on:{"save":function($event){return handleSubmit(_vm.onSaveClick)}},model:{value:(_vm.employeePayload.licenseUrl),callback:function ($$v) {_vm.$set(_vm.employeePayload, "licenseUrl", $$v)},expression:"employeePayload.licenseUrl"}})]}}],null,true)})],1),_vm._l((_vm.employeePayload.objectAttributeValues),function(objectAttributeValue,idx){return [_c('v-col',{key:idx,staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":objectAttributeValue.value,"label":objectAttributeValue.name,"index":8 + idx,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(objectAttributeValue.value),callback:function ($$v) {_vm.$set(objectAttributeValue, "value", $$v)},expression:"objectAttributeValue.value"}})]}}],null,true)})],1)]})],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }