<template>
  <v-card class="elevation-1" tile>
    <v-card-text>
      <validation-observer slim v-slot="{ handleSubmit }">
        <v-row>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'graduation')
                  ? $t(`education.${$helpers.get(employee, 'graduation').toLowerCase()}`)
                  : null
              "
              :label="$t('hr.employee.education.graduation')"
              :index="0"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <graduation-level-picker
                  v-model="employeePayload.graduation"
                  outlined
                  dense
                  style="max-width: 240px"
                  color="tertiary"
                  clearable
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'school')"
              :label="$t('hr.employee.education.school')"
              rules="max:50"
              :index="1"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.school"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="50"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'faculty')"
              :label="$t('hr.employee.education.faculty')"
              rules="max:50"
              :index="2"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.faculty"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="50"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'department')"
              :label="$t('hr.employee.education.department')"
              rules="max:50"
              :index="3"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.department"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="50"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'graduationGrade')"
              :label="$t('hr.employee.education.graduation_grade')"
              rules="min_value:0|max_value:5"
              :index="4"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model.number="employeePayload.graduationGrade"
                  type="number"
                  min="0"
                  max="5"
                  step=".1"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'academicTitle')
                  ? $t(`academic_title.${$helpers.get(employee, 'academicTitle').toLowerCase()}`)
                  : null
              "
              :label="$t('hr.employee.education.academic_title')"
              :index="5"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <academic-title-picker
                  v-model="employeePayload.academicTitle"
                  outlined
                  dense
                  style="max-width: 240px"
                  color="tertiary"
                  clearable
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                ($helpers.get(employee, 'languages') || [])
                  .map(language => $t(`education_languages.${language.toLowerCase()}`))
                  .join(', ')
              "
              :label="$t('hr.employee.education.languages')"
              :index="6"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <education-language-picker
                  v-model="employeePayload.languages"
                  multiple
                  outlined
                  dense
                  style="max-width: 240px"
                  color="tertiary"
                  clearable
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'licenseUrl')"
              :label="$t('hr.employee.education.licence_file')"
              :index="7"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              :action-container-class="{ 'align-center': true }"
              hide-save
              @click:edit="onEditClick"
              @click:cancel="onCancelClick"
            >
              <template v-slot:value="{ value }">
                <template v-if="value">
                  <licence-file v-model="employeePayload.licenseUrl" readonly style="max-width: 240px;" />
                </template>
                <span v-else>
                  -
                </span>
              </template>
              <template v-slot="{ errors }">
                <licence-file v-model="employeePayload.licenseUrl" @save="handleSubmit(onSaveClick)" />
              </template>
            </profile-field>
          </v-col>
          <template v-for="(objectAttributeValue, idx) in employeePayload.objectAttributeValues">
            <v-col :key="idx" cols="12" class="py-0">
              <profile-field
                :value="objectAttributeValue.value"
                :label="objectAttributeValue.name"
                :index="8 + idx"
                :active-index="activeIndex"
                :saving="saving"
                :loading="loading"
                :is-editable="isEditable"
                @click:edit="onEditClick"
                @click:save="handleSubmit(onSaveClick)"
                @click:cancel="onCancelClick"
              >
                <template v-slot="{ errors }">
                  <v-text-field
                    v-model="objectAttributeValue.value"
                    outlined
                    dense
                    color="tertiary"
                    style="max-width: 400px;"
                    :error-messages="errors"
                  />
                </template>
              </profile-field>
            </v-col>
          </template>
        </v-row>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
  import { EMPLOYEE_EDUCATION } from "../query";

  export default {
    name: "EmployeeEducation",
    props: {
      profileSettings: Object
    },
    components: {
      GraduationLevelPicker: () => import("@/components/selectpickers/GraduationLevelPicker"),
      AcademicTitlePicker: () => import("@/components/selectpickers/AcademicTitlePicker"),
      EducationLanguagePicker: () => import("@/components/selectpickers/EducationLanguagePicker"),
      FileUploadPicker: () => import("@/components/selectpickers/FileUploadPicker"),
      LicenceFile: () => import("./components/LicenceFile"),
      ProfileField: () => import("./components/ProfileField")
    },
    data: () => ({
      loading: false,
      employeePayload: {
        graduation: null,
        school: null,
        faculty: null,
        department: null,
        graduationGrade: null,
        academicTitle: null,
        languages: [],
        licenseUrl: null,
        objectAttributeValues: []
      },
      employee: null,
      activeIndex: -1,
      saving: false
    }),
    computed: {
      isEditable() {
        return this.$helpers.get(this.profileSettings, this.$enums.PROFILE_SETTINGS.EDUCATION);
      }
    },
    methods: {
      onSaveClick() {
        this.saving = true;
        this.$api.employeeService
          .updateEducationalCredentials(this.$store.state.auth.user.id, this.employeePayload)
          .then(response => {
            if (response.status === 200) {
              this.activeIndex = -1;
              this.fetchEmployeeEducation();
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.saving = false));
      },
      onCancelClick() {
        this.activeIndex = -1;
        this.setEmployeePayload(this.employee);
      },
      onEditClick(index) {
        this.activeIndex = index;
      },
      setEmployeePayload(employee) {
        this.employeePayload = { ...employee };
        this.employeePayload.objectAttributeValues = this.$helpers.cloneDeep(employee.objectAttributeValues);
      },
      async fetchEmployeeEducation() {
        this.loading = true;
        this.$apollo
          .query({
            query: EMPLOYEE_EDUCATION,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employee = data.employee.education;
              this.setEmployeePayload(data.employee.education);
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchEmployeeEducation();
    }
  };
</script>
